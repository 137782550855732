import enLang from "./entries/en_US";
import esLang from "./entries/es_ES";
import ptLang from "./entries/pt_PT";
import { addLocaleData } from "react-intl";

const AppLocale = {
    en: enLang,
    es: esLang,
    pt: ptLang
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.es.data);
addLocaleData(AppLocale.pt.data);

export default AppLocale;
