import * as types from '../types';

const initialState = {
    orders: [],
    orderSpecific: {},
};

const reducer = (state = initialState, action) => {
    const { payload } = action;

    switch (action.type) {

        case types.SET_USER_ORDERS:
            return { ...state, orders: payload }

        case types.GET_ORDER_PER_ID:
            return { ...state, orderSpecific: payload }

        default:
            return { ...state };
    }
}


export default reducer;
