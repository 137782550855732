import Model from '../../utils/Model';

class Menu extends Model {

	constructor(props) {
		super(props)

		this.initialize(props);

	}

	initialize(props) {
		super.initialize(props);
		

		this.name = props.name || '';
		this.description = props.description || '';

        this.picture = props.picture || '';
		
	}
}

export default Menu;

