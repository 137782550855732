import React, { Fragment } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { scroller } from 'react-scroll';
import { Link } from "react-router-dom";

import Meulocal from './Meulocal';

// intl
import IntlMessages from '~/utils/IntlMessages';

class ButtonMenuMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false // Estado para controlar se o menu está aberto ou fechado
        };
    }

    scrollTo = () => {
        let howitworks = document.querySelector('#howitworks');

        if (howitworks) {
            scroller.scrollTo('howitworks', {
                duration: 500,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        }
    }

    closeMenu = () => {
        this.setState({ isOpen: false }); // Fecha o menu alterando o estado
    }

    openMenu = () => {
        this.setState({ isOpen: true }); // Abre o menu alterando o estado
    }

    render() {
        return (
            <Menu isOpen={this.state.isOpen} onStateChange={({ isOpen }) => this.setState({ isOpen })}>
                <li className="header-link">
                    <Link
                        to="/#howitworks"
                        title="Como funciona"
                        className="header-linkinterno"
                        onClick={() => {
                            this.scrollTo();
                            this.closeMenu();
                        }}
                    >
                        <IntlMessages id="header.link.howitwork" />
                    </Link>
                </li>

                <li className="header-link"> <Meulocal /> </li>
                <li className="header-link">
                    <Link
                        className="header-linkinterno"
                        to="/contact"
                        onClick={() => {
                            this.closeMenu();
                        }}
                    >
                        <IntlMessages id="header.link.contact" />
                    </Link>
                </li>

                {
                    this.props.isAuthenticated ?
                        <Fragment>
                            <li className="header-link">
                                <Link
                                    className="header-linkinterno"
                                    onClick={() => {
                                        this.closeMenu();
                                    }}
                                    to="/profile/edit"
                                >
                                    <IntlMessages id="header.link.profile" />
                                </Link>
                            </li>
                            <li className="header-link" onClick={() => {
                                this.closeMenu();
                            }}>
                                <Link
                                    className="header-linkinterno"
                                    to="/reserve"
                                >
                                    <IntlMessages id="header.link.order" />
                                </Link>
                            </li>
                            <li className="header-link">
                                <Link
                                    className="header-linkinterno"
                                    to="/profile#logout"
                                    onClick={() => {
                                        this.props.handlerLogout();
                                        this.closeMenu();
                                    }}
                                >
                                    <IntlMessages id="header.link.logout" />
                                </Link>
                            </li>
                        </Fragment>
                        :
                        <li className="header-link">
                            <Link
                                className="header-linkinterno"
                                to="/sigin"
                                onClick={() => {
                                    this.closeMenu(); // Fecha o menu ao clicar no link
                                }}
                            >
                                <IntlMessages id="header.link.login" />
                            </Link>
                        </li>
                }
            </Menu>
        );
    }
}

export default ButtonMenuMobile;
