import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";

import Loading from "./common/components/Loading";
import Routes from "./routes";

import config from "~/config";

import { authCheck } from "./modules/web/store/actions/actions-auth";
import { fetchUser } from "./modules/web/services/services-auth";
import { getIntl, setIntl } from "./modules/web/services/services-geoip";

// intl
import { IntlProvider } from "react-intl";
import AppLocale from "./lngProvider";
import { CookieBanner } from "./CookieBanner";
class App extends Component {
    /**
     *
     */
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            refresh: false,
            isDesktop: false,
            showCookieBanner:
                localStorage.getItem("cookies-accepted") !== "true"
        };

        this.props.dispatch(authCheck());
        this.props.dispatch(getIntl());

        this.screenSize = this.screenSize.bind(this);
        this.handleAcceptCookies = this.handleAcceptCookies.bind(this);
    }

    componentDidMount() {
        this.screenSize();

        this.setState({ refresh: true });

        window.addEventListener("resize", this.screenSize);
    }

    componentWillMount() {
        window.removeEventListener("resize", this.screenSize);
    }

    componentDidUpdate() {
        if (
            Object.keys(this.props.intl).length === 0 &&
            this.props.intl.constructor === Object &&
            this.state.loading
        ) {
            this.props.dispatch(setIntl());
        }

        if (
            this.state.refresh &&
            this.state.loading &&
            this.props.isAuthenticated
        ) {
            this.props.dispatch(fetchUser()).then(user => {
                if (
                    (user.group === "restaurant" && user.restaurant) ||
                    user.group === "franchisee" ||
                    user.group === "admin"
                ) {
                    window.location.href = config.app.PANELURL;
                }

                this.setState({
                    loading: false,
                    refresh: false
                });
            });
        } else if (this.state.refresh && this.state.loading) {
            this.setState({
                loading: false,
                refresh: false
            });
        }
    }

    screenSize() {
        this.setState({ isDesktop: true });
    }

    handleAcceptCookies() {
        localStorage.setItem("cookies-accepted", "true");
        this.setState({ showCookieBanner: false });
    }

    render() {
        const { locale } = this.props;
        const currentAppLocale = AppLocale[locale.locale];

        return this.state.loading ? (
            <Loading />
        ) : (
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >
                <BrowserRouter>
                    <div>
                        <Routes
                            isDesktop={this.state.isDesktop}
                            {...this.props}
                        />
                        {this.state.showCookieBanner && (
                            <CookieBanner onAccept={this.handleAcceptCookies} />
                        )}
                    </div>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth,
    intl: state.geoip.intl,
    locale: state.geoip.locale,
    regions: state.restaurants.regions
});

export default connect(mapStateToProps)(App);
