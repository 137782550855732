import * as types from '../types';

const initialState = {
    info_order: ''
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

		case types.SAVE_INF_ORDER:
			return saveInfOrder(state, action.payload);

        default:
            return { ...state };
    }
}

const saveInfOrder = (state, payload) => {

    localStorage.setItem('info_order', payload);

    return {
		...state,
		info_order: payload
	}
}

export default reducer;
