import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({
    component: Component,
    isAuthenticated,
    header,
    footer,
    user,
    ...rest
}) => {
    let properties = { ...rest };

    header =
        header === undefined ? { transparent: false, simple: false } : header;
    footer = footer === undefined ? { white: false } : footer;

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Component
                        {...props}
                        isDesktop={properties.isDesktop}
                        header={header}
                        footer={footer}
                    />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/sigin",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    location: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired
};

function mapStateToProps(store) {
    return {
        isAuthenticated: store.auth.isAuthenticated,
        user: store.auth
    };
}

export default connect(mapStateToProps)(PrivateRoute);
