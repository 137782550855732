import Http from "../../../utils/Http";
import * as actions from "../store/actions/actions-geoip";

/**
 * fetch the current country user
 *
 * @returns {function(*)}
 */
export const setIntl = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            Http.get(
                "/site/geoip?fields=ip,country_code,latitude,longitude,location"
            )
                .then(res => {
                    dispatch(actions.setIntl(res.data.data));
                    resolve();
                })
                .catch(err => {
                    console.error("Erro na requisição HTTP:", err);
                    reject(err);
                });
        });
    };
};

/**
 * fetch the current country user
 *
 * @returns {function(*)}
 */
export const getIntl = () => {
    return dispatch => dispatch(actions.getIntl());
};
