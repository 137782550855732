import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

const PublicRoutes = ({ component: Component, header, footer, ...rest }) => {
    let properties = { ...rest };

    header =
        header === undefined ? { transparent: false, simple: false } : header;
    footer = footer === undefined ? { white: false } : footer;

    return (
        <Route
            {...rest}
            render={props => (
                <Component
                    {...props}
                    isDesktop={properties.isDesktop}
                    header={header}
                    footer={footer}
                />
            )}
        />
    );
};

PublicRoutes.propTypes = {
    component: PropTypes.func.isRequired,
    location: PropTypes.object
};

export default PublicRoutes;
