import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Header from "~/common/components/Header";
import Footer from "~/common/components/Footer";
import FooterMobile from "~/common/components/FooterMobile";
import FooterLP from "~/common/components/FooterLP";

const propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object
};

const PublicLayout = ({
    children,
    transparent,
    simple,
    white,
    dispatch,
    location,
    isDesktop,
    isAuthenticated,
    user,
    intl,
    regions
}) => {
    if (location.pathname.match(/\/checkout\/voucher\//)) {
        return <Fragment>{children}</Fragment>;
    }

    return (
        <Fragment>
            <Header
                transparent={transparent || false}
                simple={simple || false}
                dispatch={dispatch}
                isAuthenticated={isAuthenticated}
                user={user}
                intl={intl}
                regions={regions}
                location={location}
            />

            {children}

            {location.pathname === "/landingpage" ||
            location.pathname === "/restaurant/pre-register" ? (
                <Footer classBg={white ? " " : "footer-home"} />
            ) : isDesktop ? (
                <Footer classBg={white ? " " : "footer-home"} />
            ) : (
                <FooterMobile classBg={white ? " " : "footer-home"} />
            )}
        </Fragment>
    );
};

PublicLayout.propTypes = propTypes;

export default PublicLayout;
