
import * as types from '../types';

export const authCheck = () => {
	return {
		type: types.AUTH_CHECK
	}
}

export const authSigin = (payload) => {
	return {
		type: types.AUTH_SIGIN,
		payload,
	};
}

export const authLogout = () => {
	return {
		type: types.AUTH_LOGOUT,
	}
}

export const authUser = (payload) => {
	return {
		type: types.AUTH_USER,
		payload
	}
}

export const saveLocationToRedirect = (payload) => {
	return {
		type: types.SAVE_LOCATION_TO_REDIRECT,
		payload
	}
}
