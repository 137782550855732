const config = {
    dev: {
        app: {
            URL: "http://localhost:3000/",
            BASEDOMAIN: "localhost",
            BASENAME: "http://localhost:3000/",
            CDN: "http://localhost:3000",
            PANELURL: "https://firstoeat-panel.atuo.com.br"
        },
        apiGateway: {
            URL: "https://evolutionsoft.dev.br:9000/api",
            // URL: "https://api.firstoeat.com/api",
            // URL: "https://firstoeat-api.atuo.com.br/api",
            VERSION: "v1"
        }
    },
    hom: {
        app: {
            URL: "https://firstoeat.com.br:3000/",
            BASEDOMAIN: "firstoeat.com.br",
            BASENAME: "https://firstoeat.com.br:3000/",
            CDN: "https://www.firstoeat.com.br",
            PANELURL: "https://firstoeat-panel.atuo.com.br"
        },
        apiGateway: {
            // URL: 'https://localhost:3001/api',
            URL: "https://firstoeat-api.atuo.com.br/api",
            VERSION: "v1"
        }
    },
    prod: {
        app: {
            URL: "https://www.firstoeat.com.br/",
            BASEDOMAIN: "firstoeat.com.br",
            BASENAME: "https://www.firstoeat.com.br/",
            CDN: "https://www.firstoeat.com.br",
            PANELURL: "https://administracao.firstoeat.com.br/"
        },
        apiGateway: {
            URL: "https://evolutionsoft.dev.br:9000/api",
            VERSION: "v1"
        }
    }
};

module.exports = config.prod;
