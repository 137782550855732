import React from "react";
import { Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import routes from "./routes";

import PrivateRoute from "./Private";
import PublicRoute from "./Public";

import Layout from "~/layout";
import config from "~/config";

const history = createBrowserHistory();

class Routes extends React.Component {
    // const Routes = ({ isDesktop, intl, regions, user = null }) => {

    componentDidMount() {
        this.checkUserBlockUrl();
    }

    componentDidUpdate() {
        this.checkUserBlockUrl();
    }

    checkUserBlockUrl() {
        const { user } = this.props;

        if (
            user &&
            user.group === "restaurant" &&
            user.restaurant === undefined
        ) {
            if (
                history.location.pathname !== "/restaurant/register" ||
                history.location.pathname !== "/restaurant/wait-approval"
            ) {
                history.push("/restaurant/register");
            }
        }
    }

    render() {
        const { isDesktop, intl, regions } = this.props;

        return (
            <Router
                onUpdate={() => window.scrollTo(0, 0)}
                history={history}
                basename={config.app.BASENAME}
            >
                <Layout isDesktop={isDesktop} intl={intl} regions={regions}>
                    <Switch>
                        {routes.map((route, i) => {
                            route = { ...route, intl };

                            if (route.auth) {
                                return (
                                    <PrivateRoute
                                        key={i}
                                        {...route}
                                        isDesktop={isDesktop}
                                    />
                                );
                            }

                            return (
                                <PublicRoute
                                    key={i}
                                    {...route}
                                    isDesktop={isDesktop}
                                />
                            );
                        })}
                    </Switch>
                </Layout>
            </Router>
        );
    }
}

export default Routes;
