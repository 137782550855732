import Model from '../../utils/Model';
import Address from './Address';
import Menu from './Menu';
import Price from './Price';
import Review from './Review';
import Service from './Service';
import Table from './Table';
import Worksheet from './Worksheet';
import User from '../user/User';

import moment from 'moment';

class Restaurant extends Model {

    constructor(props) {
        super(props)

        this.initialize(props);

    }

    initialize(props) {

        super.initialize(props);

        this._id = props._id || false;
        this.active = props.active || false;
        this.show = props.show || false;

        this.user = props.user || '';
        this.region = props.region || '';
        this.company_name = props.company_name || '';
        this.ticket_avg = props.ticket_avg || '';
        this.phone = props.phone || '';
        this.table_tolerance = props.table_tolerance || '';
        this.description = props.description || '';
        this.time_open = props.time_open || '';
        this.max_places = props.max_places || '';
        this.logo = props.logo || '';
        this.cover = props.cover || '';
        this.website = props.website || '';
        this.facebook = props.facebook || '';
        this.more = props.more || '';
        this.slug = props.slug || '';

        this.address = props.address ? props.address.map((address) => new Address(address)) : [];
        this.photos = [];
        this.prices = props.prices ? props.prices.map((price) => new Price(price)) : [];
        this.reviews = props.reviews ? props.reviews.map((review) => new Review(review)) : [];
        this.menu = props.menu ? props.menu.map((menu) => new Menu(menu)) : [];
        this.kitchen = props.kitchen || [];
        this.services = props.services ? props.services.map((service) => new Service(service)) : [];
        this.user = props.user ? new User(props.user) : new User({});

        this.created = props.created || '';

        // worksheet
        this.worksheet = props.worksheet
            ? props.worksheet.map((worksheet) => new Worksheet(worksheet))
            : Array.apply(null, { length: 7 })
                .map((item, i) => new Worksheet(
                    {
                        day: moment().locale('pt-br').day(i).format('ddd'),
                        time_open: 'close',
                        time_close: 'close'
                    }
                ));

        // tables
        const arrMorning = Array.apply(null, { length: 7 }).map((item, i) => new Table(
            {
                day: moment().locale('pt-br').day(i).format('ddd'),
                time: 'close',
                period: 'morning',
                qtd: 0,
                places: 0
            }
        ));

        const arrAfternoon = Array.apply(null, { length: 7 }).map((item, i) => new Table(
            {
                day: moment().locale('pt-br').day(i).format('ddd'),
                time: 'close',
                period: 'afternoon',
                qtd: 0,
                places: 0
            }
        ));

        const arrNight = Array.apply(null, { length: 7 }).map((item, i) => new Table(
            {
                day: moment().locale('pt-br').day(i).format('ddd'),
                time: 'close',
                period: 'night',
                qtd: 0,
                places: 0
            }
        ));

        this.tables = props.tables
            ? props.tables.map((table) => new Table(table))
            : [...arrMorning, ...arrAfternoon, ...arrNight].map(item => new Table(item))
    }
}

export default Restaurant;

