import * as types from '../types';

const initialState = {
    data: {
        docs: []
    }
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case types.GET_ALL_FAQS:
            return { ...state, data: action.payload };

        default:
            return { ...state };
    }
}

export default reducer;
