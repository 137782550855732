
import * as types from '../types';

export const getIntl = () => {
    return {
        type: types.GET_INTL
    };
};

export const setIntl = (payload) => {
    return {
        type: types.SET_INTL,
        payload
    };
};

export const setRegion = (payload) => {
    return {
        type: types.SET_REGION,
        payload
    };
};

export const switchLanguage = (payload) => {
    return {
        type: types.SWITCH_LANGUAGE,
        payload: payload
    };
}
