import { combineReducers } from 'redux';

import layout from '~/modules/web/store/reducers/reducers-layout';
import auth from '~/modules/web/store/reducers/reducers-auth';
import restaurants from '~/modules/web/store/reducers/reducers-restaurant';
import createRestaurant from '~/modules/web/store/reducers/reducers-createRestaurant';
import checkout from '~/modules/web/store/reducers/reducers-checkout';
import geoip from '~/modules/web/store/reducers/reducers-geoip';
import orders from '~/modules/web/store/reducers/reducers-orders';
import systemConfig from '~/modules/web/store/reducers/reducers-system-config';
import privacyPolicy from '~/modules/web/store/reducers/reducers-privacy-policy';
import useTerms from '~/modules/web/store/reducers/reducers-use-terms';
import faq from '~/modules/web/store/reducers/reducers-faq';

export default combineReducers({
    layout,
    auth,
    restaurants,
    checkout,
    geoip,
    createRestaurant,
    orders,
    systemConfig,
    privacyPolicy,
    useTerms,
    faq
})
