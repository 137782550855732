import React, { Component } from "react";

import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

import config from "~/config";

// intl
import IntlMessages from "~/utils/IntlMessages";

import FooterIMG from "../../assets/images/logo-black.png";
import PagarIMG from "../../assets/images/Pagar-me-e-confiavel1.png";

export default class Footer extends Component {
    scrollTo = () => {
        let howitworks = document.querySelector("#howitworks");

        if (howitworks) {
            scroller.scrollTo("howitworks", {
                duration: 500,
                delay: 0,
                smooth: "easeInOutQuart"
            });
        }
    };

    render() {
        return (
            <footer className={`footer ${this.props.classBg}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-3 offset-md-1 d-flex">
                            <div className="logo">
                                <img src={FooterIMG} alt="" />
                                <span>
                                    <IntlMessages id="footer.rights" />
                                </span>
                            </div>
                        </div>

                        <div className="col-12 col-md-7">
                            <div className="wrap" style={{textAlign: "center"}}>
                                <ul>
                                    <li className="title">
                                        <IntlMessages id="footer.about" />
                                    </li>

                                    <li>
                                        <Link
                                            to="/#howitworks"
                                            title="Como funciona"
                                            onClick={() => this.scrollTo()}
                                        >
                                            <IntlMessages id="footer.howitworks" />
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/terms">
                                            <IntlMessages id="footer.terms" />
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/privacy-policy">
                                            <IntlMessages id="footer.privacy" />
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/be-a-partner">
                                            <IntlMessages id="footer.partners.beapartner" />
                                        </Link>
                                    </li>

                                    {/* <li>
										<Link to="/blog">Blog</Link>
									</li> */}
                                </ul>

                                <ul>
                                    <li className="title">
                                        <IntlMessages id="footer.partners" />
                                    </li>

                                    <li>
                                        <Link to="/restaurant/pre-register">
                                            <IntlMessages id="footer.partners.add-restaurant" />
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/restaurant/sigin">
                                            <IntlMessages id="footer.partners.login-restaurant" />
                                        </Link>
                                    </li>
                                </ul>

                                <ul>
                                    <li className="title">
                                        <IntlMessages id="footer.help" />
                                    </li>

                                    <li>
                                        <Link to="/faq">
                                            <IntlMessages id="footer.questions" />
                                        </Link>
                                    </li>

                                    {/* <li>
                                        <Link to="/contact">
                                            <IntlMessages id="footer.press" />
                                        </Link>
                                    </li> */}

                                    <li>
                                        <Link to="/contact">
                                            <IntlMessages id="footer.talk" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-10 offset-1 ">
                            <div className="logo-content float-right">
                                <img src={PagarIMG} alt="Paypal" />

                                <img
                                    src={`${config.app.CDN}/assets/images/visa.png`}
                                    alt="Visa"
                                />
                                <img
                                    src={`${config.app.CDN}/assets/images/mastercard.png`}
                                    alt="Mastercard"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
