
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchUser } from '~/modules/web/services/services-auth';

import PublicLayout from './Public';

class Layout extends Component {

	static displayName = 'Layout';

	static propTypes = {
		isAuthenticated: PropTypes.bool.isRequired,
		user: PropTypes.object.isRequired,
		children: PropTypes.node.isRequired,
		dispatch: PropTypes.func.isRequired,
	}

	componentDidMount() {

		const { isAuthenticated, user } = this.props

		if (isAuthenticated && user._id === '') {

			this.props.dispatch(fetchUser());

		}
	}

	render() {

		const { children, ...props } = this.props;

		return <PublicLayout {...props} > {children} </PublicLayout>

	}
}

const mapStateToProps = state => {

	return {

		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth,
		transparent: state.layout.header_transparent,
		simple: state.layout.header_simple,
		white: state.layout.footer_white

	}
}

export default withRouter(connect(mapStateToProps)(Layout));
