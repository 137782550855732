// dependencies
import React from "react";
import ReactSVG from "react-svg";
// import config from "~/config";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

import Meulocal from "./Meulocal";
import ButtonMenuMobile from "./ButtonMenuMobile";
import ButtonLang from "./ButtonLang";

import { getRegions } from "~/modules/web/services/services-restaurant";
import { authLogout } from "~/modules/web/store/actions/actions-auth";
import svgLogo from "~/assets/images/firstoeat-logo.svg";

import Loading from "./Loading";

// intl
import IntlMessages from "~/utils/IntlMessages";

class Header extends React.Component {
    componentDidMount() {
        const { dispatch, intl, regions } = this.props;

        if (
            Object.keys(intl).length !== 0 &&
            (regions === undefined || regions.length === 0)
        ) {
            dispatch(getRegions(intl.country_code.toLowerCase()));
        }
    }

    componentDidUpdate() {
        const {
            dispatch,
            intl,
            regions,
            location: { hash }
        } = this.props;

        if (hash === "#howitworks") {
            this.scrollTo();
        }

        if (
            Object.keys(intl).length !== 0 &&
            (regions === undefined || regions.length === 0)
        ) {
            dispatch(getRegions(intl.country_code.toLowerCase()));
        }
    }

    handlerLogout = async () => {
        await this.props.dispatch(authLogout());
    };

    renderLoginProfileLink() {
        const { isAuthenticated } = this.props;

        if (isAuthenticated) {
            // if (user.group === "restaurant") {
            // return (
            //     <span
            //         className="header-linkinterno"
            //         onClick={this.handlerLogout}
            //     >
            //         <IntlMessages id="header.link.logout" />
            //     </span>
            // );
            // }

            return (
                <div
                    style={{
                        display: "flex",
                        gap: "30px"
                    }}
                >
                    <Link className="header-linkinterno" to="/profile/edit">
                        <IntlMessages id="header.link.profile" />
                    </Link>
                    <span
                        className="header-linkinterno"
                        onClick={this.handlerLogout}
                    >
                        <IntlMessages id="header.link.logout" />
                    </span>
                </div>
            );
        }

        return (
            <Link className="header-linkinterno" to="/sigin">
                <IntlMessages id="header.link.login" />
            </Link>
        );
    }

    renderLoginProfileLinkk() {
        const { isAuthenticated } = this.props;

        if (isAuthenticated) {
            return (
                <div
                    style={{
                        display: "flex",
                        gap: "30px"
                    }}
                >
                    <Link
                        to="/reserve"
                        title="Minhas Reservas"
                        className="header-linkinterno"
                    >
                        <IntlMessages id="header.link.reserve" />
                    </Link>{" "}
                </div>
            );
        }

        // return (
        //     <Link className="header-linkinterno" to="/sigin">
        //         <IntlMessages id="header.link.login" />
        //     </Link>
        // );
    }

    scrollTo = () => {
        let howitworks = document.querySelector("#howitworks");

        if (howitworks) {
            scroller.scrollTo("howitworks", {
                duration: 500,
                delay: 0,
                smooth: "easeInOutQuart"
            });
        }
    };

    renderLinks() {
        return (
            <nav className="container">
                <ul className="header-menu d-none d-md-block">
                    <li className="header-link">
                        <Link
                            to="/#howitworks"
                            title="Como funciona"
                            className="header-linkinterno"
                            onClick={() => this.scrollTo()}
                        >
                            <IntlMessages id="header.link.howitwork" />
                        </Link>
                    </li>
                    <li className="header-link">
                        <Meulocal />
                    </li>

                    {window.innerWidth >= 768 && window.innerWidth <= 1253 ?

                        <>
                            <li className="header-link">
                                <a href="/">
                                    <button className="header-linkinterno">
                                        Home
                                    </button>
                                </a>
                            </li>
                        </>

                        :

                        <li className="header-link">
                            <Link
                                to={`/`}
                                title="Experimente novas sensações todos os dias"
                                className="header-linkinterno"
                            >
                                <ReactSVG
                                    src={svgLogo}
                                    className="header-logo img-fluid"
                                />
                            </Link>
                        </li>
                    }

                    <li className="header-link" style={{ padding: "20px 10px", bottom: "0" }}>
                        {this.renderLoginProfileLinkk()}
                    </li>
                    <li className="header-link" style={{ padding: "20px 10px", bottom: "0" }}>
                        <Link
                            to="/restaurant/pre-register"
                            title="Cadastre seu restaurante"
                            className="header-linkinterno"
                        >
                            <IntlMessages id="footer.partners.add-restaurant" />
                        </Link>{" "}
                    </li>

                    <li className="header-link">
                        {this.renderLoginProfileLink()}
                    </li>
                </ul>
            </nav>
        );
    }

    renderLogo() {
        return (
            <nav className="container">
                <ul className="header-menu d-none d-md-block">
                    <li className="header-link">
                        <Link
                            to={`/`}
                            title="Voltar para a home"
                            className="header-linkinterno"
                        >
                            <ReactSVG
                                src={svgLogo}
                                className="header-logo img-fluid"
                            />
                        </Link>
                    </li>
                </ul>
            </nav>
        );
    }

    render() {
        const { isAuthenticated, intl } = this.props;

        if (Object.keys(intl).length === 0) {
            return <Loading />;
        }

        return (
            <header style={{ background: "#e83921" }}
                className={`header position-relative ${this.props.transparent ? "header-transparent" : ""
                    }`}
            >
                <ButtonMenuMobile
                    isAuthenticated={isAuthenticated}
                    handlerLogout={this.handlerLogout}
                />

                <div className="container-fluid header-logomobile">
                    <ButtonLang {...this.props} />

                    {/* Menu mobile */}
                    <div className="d-block d-md-none text-center pb-4">
                        <Link
                            to={`/`}
                            title="Voltar para a home"
                            className="header-linkinterno"
                        >
                            <ReactSVG
                                src={svgLogo}
                                className="header-logo img-fluid mx-auto"
                            />
                        </Link>
                    </div>

                    {this.props.simple ? this.renderLogo() : this.renderLinks()}
                </div>
            </header>
        );
    }
}

Header.defaultProps = {
    transparent: false,
    simple: false
};

export default Header;
