import Model from '../../utils/Model';

class Table extends Model {

    constructor(props) {
        super(props)

        this.initialize(props);

    }

    initialize(props) {
        super.initialize(props);

        this._id = props._id || '';
        this.day = props.day || '';
        this.time = props.time || '';
        this.period = props.period || '';
        this.qtd = props.qtd || 0;
        this.places = props.places || 0;

    }
}

export default Table;

