import * as types from '../types';

// models
import Restaurant from '~/models/restaurant/Restaurant';

const initialState = {
    restaurant: new Restaurant({}),
}

const reducer = (state = initialState, action) => {


    switch (action.type) {

        case types.CHANGE_RESTAURANT:
            return { ...state, restaurant: { ...state.restaurant, ...action.payload } };

        default:
            return { ...state };
    }

};

export default reducer;
