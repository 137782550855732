import Model from '../../utils/Model';

class Price extends Model {

	constructor(props) {
		super(props)

		this.initialize(props);

	}

	initialize(props) {
		super.initialize(props);
		

		this.places = props.places || '';
		this.price = props.price || '';

	}
}

export default Price;

