// TYPES LAYOUT
export const HEADER_TO_TRANSPARENT = "HEADER_TRANSPARENT";
export const HEADER_TO_SIMPLE = "HEADER_SIMPLE";
export const FOOTER_TO_WHITE = "FOOTER_TO_WHITE";

// TYPES RESTAURANT
export const GET_RESTAURANTS = "GET_RESTAURANTS";
export const GET_RESTAURANT = "GET_RESTAURANT";
export const GET_REGIONS = "GET_REGIONS";
export const GET_SERVICES = "GET_SERVICES";
export const GET_KITCHENS = "GET_KITCHENS";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_CITIES = "GET_CITIES";
export const GET_CEP = "GET_CEP";
export const GET_EVALUATIONS = "GET_EVALUATIONS";

// TYPES AUTH
export const AUTH_CHECK = "AUTH_CHECK";
export const AUTH_SIGIN = "AUTH_SIGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_USER = "AUTH_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SAVE_LOCATION_TO_REDIRECT = "SAVE_LOCATION_TO_REDIRECT";

// TYPES ORDERS
export const SET_USER_ORDERS = "SET_USER_ORDERS";
export const GET_ORDER_PER_ID = "GET_ORDER_PER_ID";
export const CANCEL_ORDER = "CANCEL_ORDER";

// TYPES GEOIP
export const GET_INTL = "GET_INTL";
export const SET_INTL = "SET_INTL";
export const SET_REGION = "SET_REGION";
export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";

// TYPES CONFIG
export const GET_ALL_CONFIG = "GET_ALL_CONFIG";
export const GET_DAYS_TO_CANCEL_ORDER = "GET_DAYS_TO_CANCEL_ORDER";

// TYPES CHECKOUT
export const SAVE_INF_ORDER = "SAVE_INF_ORDER";

// TYPES CREATE RESTAURANT
export const CHANGE_RESTAURANT = "CHANGE_RESTAURANT";

// TYPES STATIC CONTENT
export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";
export const GET_USE_TERMS = "GET_USE_TERMS";

// TYPES FAQ
export const GET_ALL_FAQS = "GET_ALL_FAQS";
