import Loadable from "react-loadable";

import LoadingComponent from "~/common/components/Loading";

export default [
    {
        path: "/",
        exact: true,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { white: false },
        component: Loadable({
            loader: () => import("./pages/Home"),
            loading: LoadingComponent
        })
    },
    {
        path: "/contact",
        exact: true,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Contact"),
            loading: LoadingComponent
        })
    },
    {
        path: "/reserve",
        exact: true,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Reserve/Description"),
            loading: LoadingComponent
        })
    },
    {
        path: "/infos-reserve/:id",
        exact: true,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/InfoReserve/Description"),
            loading: LoadingComponent
        })
    },
    {
        path: "/avalia/:id",
        exact: true,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { red: true },
        component: Loadable({
            loader: () => import("./pages/Avalia/Description"),
            loading: LoadingComponent
        })
    },
    {
        path: "/be-a-partner",
        exact: true,
        auth: false,
        header: { transparent: false, simple: true },
        footer: { white: false },
        component: Loadable({
            loader: () => import("./pages/LandingPage"),
            loading: LoadingComponent
        })
    },
    {
        path: "/blog",
        exact: true,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { white: false },
        component: Loadable({
            loader: () => import("./pages/Blog/ListAll"),
            loading: LoadingComponent
        })
    },
    {
        path: "/blog/details/:id",
        exact: true,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { white: false },
        component: Loadable({
            loader: () => import("./pages/Blog/Single"),
            loading: LoadingComponent
        })
    },
    {
        path: "/faq",
        exact: true,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { white: false },
        component: Loadable({
            loader: () => import("./pages/Faq"),
            loading: LoadingComponent
        })
    },
    {
        path: "/terms",
        exact: true,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { white: false },
        component: Loadable({
            loader: () => import("./pages/Terms"),
            loading: LoadingComponent
        })
    },
    {
        path: "/privacy-policy",
        exact: true,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { white: false },
        component: Loadable({
            loader: () => import("./pages/Privacy"),
            loading: LoadingComponent
        })
    },
    {
        path: "/checkout",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Checkout/Reserve"),
            loading: LoadingComponent
        })
    },
    {
        path: "/checkout/payment",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Checkout/Payment"),
            loading: LoadingComponent
        })
    },
    {
        path: "/infos-reserve/:id",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Profile/Details"),
            loading: LoadingComponent
        })
    },
    {
        path: "/checkout/finish",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Checkout/Finish"),
            loading: LoadingComponent
        })
    },
    {
        path: "/profile",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Profile/Exhibition"),
            loading: LoadingComponent
        })
    },
    {
        path: "/profile/edit",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Profile/Edit"),
            loading: LoadingComponent
        })
    },
    {
        path: "/reserve",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Profile/Orders"),
            loading: LoadingComponent
        })
    },
    {
        path: "/profile/orders/rating",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Profile/Rating"),
            loading: LoadingComponent
        })
    },
    {
        path: "/profile/tickets",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Profile/Tickets"),
            loading: LoadingComponent
        })
    },
    {
        path: "/profile/cards/read",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Profile/Cards/Read"),
            loading: LoadingComponent
        })
    },
    {
        path: "/profile/cards/create",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Profile/Cards/Create"),
            loading: LoadingComponent
        })
    },
    {
        path: "/profile/cards/update/:id/:card",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Profile/Cards/Update"),
            loading: LoadingComponent
        })
    },
    {
        path: "/profile/assessments/:id",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Profile/Assessments"),
            loading: LoadingComponent
        })
    },
    {
        path: "/restaurant/pre-register",
        exact: true,
        auth: false,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Restaurant/PreRegister"),
            loading: LoadingComponent
        })
    },
    {
        path: "/restaurant/sigin",
        exact: true,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/LoginRestaurant"),
            loading: LoadingComponent
        })
    },
    {
        path: "/restaurant/register",
        exact: true,
        auth: true,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Restaurant/Register"),
            loading: LoadingComponent
        })
    },
    {
        path: "/restaurant/wait-approval",
        exact: true,
        auth: false,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Restaurant/WaitApproval"),
            loading: LoadingComponent
        })
    },
    {
        path: "/restaurant/description/:id",
        exact: true,
        auth: false,
        header: { transparent: true, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Restaurant/Description"),
            loading: LoadingComponent
        })
    },
    {
        path: "/restaurants/:iso/:region?",
        exact: false,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Restaurant/Read"),
            loading: LoadingComponent
        })
    },
    {
        path: "/sigin",
        exact: true,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/Login"),
            loading: LoadingComponent
        })
    },
    {
        path: "/auth/reset_password/:token",
        exact: true,
        auth: false,
        header: { transparent: false, simple: false },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/ChangePassword"),
            loading: LoadingComponent
        })
    },
    {
        path: "/checkout/voucher/:token",
        exact: true,
        auth: false,
        header: { transparent: false, simple: true },
        footer: { white: true },
        component: Loadable({
            loader: () => import("./pages/printVoucher"),
            loading: LoadingComponent
        })
    },
    {
        exact: true,
        component: Loadable({
            loader: () => import("./pages/Home"),
            loading: LoadingComponent
        })
    }
];
