import * as moment from "moment";
import "moment/locale/pt-br";
import Cookies from "universal-cookie";

// import config from '~/config';
import HTTP from '../../../../utils/Http';
import User from '../../../../models/user/User';

import * as types from '../types';

const cookies = new Cookies();

const initialState = Object.assign({}, { isAuthenticated: false }, new User({}));

const reducer = (state = initialState, action) => {

	switch (action.type) {

		case types.AUTH_CHECK:
			return checkAuth(state);

		case types.AUTH_SIGIN:
			return sigin(state, action.payload);

		case types.AUTH_LOGOUT:
			return logout(state);

		case types.AUTH_USER:
			return { ...state, ...action.payload };

		case types.SAVE_LOCATION_TO_REDIRECT:
			return saveLocationToRedirect(state, action.payload);

		default:
			return { ...state };
	}
}

const saveLocationToRedirect = (state, payload) => {

	localStorage.setItem('location_redirect', payload);

	return {
		...state,
		location: payload,
	}
}

const checkAuth = (state) => {

	state = Object.assign({}, state, {
		isAuthenticated: !!cookies.get("access_token")
	})

	if (state.isAuthenticated) {
		HTTP.defaults.headers.common['Authorization'] = `Bearer ${cookies.get("access_token")}`;
	}

	return state;
}

const sigin = (state, payload) => {

	let expires = moment()
		.add(20, "days")
		.format();

	expires = new Date(expires);

	// cookies.set("access_token", payload, { path: '/', expires, domain: config.app.BASEDOMAIN });
	cookies.set("access_token", payload, { path: '/', expires });

	HTTP.defaults.headers.common['Authorization'] = `Bearer ${payload}`;

	return {
		...state,
		isAuthenticated: true,
	}
}

const logout = (state) => {

	// cookies.remove("access_token", { path: '/', domain: config.app.BASEDOMAIN });
	cookies.remove("access_token", { path: '/' });

	if (localStorage.getItem('info_order') !== null) {
		localStorage.removeItem('info_order');
	}

	if (localStorage.getItem('location_redirect') !== null) {
		localStorage.removeItem('location_redirect');
	}

	state = Object.assign({}, { isAuthenticated: false }, new User({}));

	return { ...state }
}

export default reducer;
