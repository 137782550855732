import Axios from "axios";
import React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getRestaurants } from "~/modules/web/services/services-restaurant";

import { setRegion } from "~/modules/web/store/actions/actions-geoip";

// intl
import IntlMessages from "~/utils/IntlMessages";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)"
    }
};

class Meulocal extends React.Component {
    constructor() {
        super();

        this.state = {
            modalIsOpen: false,
            regionsArray: []
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        document.body.classList.add("modal-open");
        this.setState({ modalIsOpen: true });
    }

    componentDidMount() {
        Axios.get(`/site/regions/BR`, { params: { filter: true } })
            .then(response => {
                let regionArray = response.data.data.citiesRestaurants;
                this.setState({
                    regionsArray: regionArray
                });
                // console.log("Regions:", this.state.regionsArray);
            })
            .catch(error => {
                console.error("Erro ao consultar Cidades:", error);
            });
    }

    closeModal(e, region, city) {
        this.props.dispatch(setRegion(region));

        if (region && region.iso) {
            // Adicione a cidade ao corpo da requisição
            const requestBody = {
                city: city
            };

            // Chame a API passando o corpo da requisição com a cidade
            this.props
                .dispatch(getRestaurants(region.iso, requestBody))
                .then(() => {
                    // Ações adicionais após a requisição (se necessário)
                })
                .catch(error => {
                    // Trate os erros, se necessário
                    console.error("Erro ao chamar a API:", error);
                });

            document.body.classList.remove("modal-open");
            this.setState({ modalIsOpen: false });
        } else {
            this.setState({ modalIsOpen: false });
        }
    }
    render() {
        const { regionsArray, modalTitle } = this.props;

        return (
            <div>
                <button
                    onClick={this.openModal}
                    className="header-linkinterno"
                    style={{
                        color: modalTitle ? "black" : "",
                        fontSize: modalTitle ? "1.2rem" : ""
                    }}
                >
                    {modalTitle ? (
                        <IntlMessages id={modalTitle} />
                    ) : (
                        <IntlMessages id="header.link.make-your-reservation" />
                    )}
                </button>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Cidade"
                    ariaHideApp={false}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mb-4 pb-3">
                                <h4 className="modal-title">
                                    <p
                                        style={{
                                            fontSize: "1.5rem",
                                            fontWeight: "bold",
                                            color: "black"
                                        }}
                                    >
                                        Escolha sua Cidade
                                    </p>
                                </h4>
                            </div>

                            <div className="col-12 modal-listcontainer">
                                <div className="row">
                                    <div className="col-12">
                                        <ul className="modal-listcontent">
                                            {this.state.regionsArray ? (
                                                this.state.regionsArray.map(
                                                    (region, i) => {
                                                        console.log(
                                                            "Region:",
                                                            region
                                                        );
                                                        return (
                                                            region.hasRestaurant && (
                                                                <Link
                                                                    className="modal-link"
                                                                    to={{
                                                                        pathname: `/restaurants/${region.region.iso}/${region.city}`,
                                                                        state: {
                                                                            cityId:
                                                                                region.city
                                                                        }
                                                                    }}
                                                                    onClick={e =>
                                                                        this.closeModal(
                                                                            window.location.reload(),
                                                                            e,
                                                                            region,
                                                                            region.city
                                                                        )
                                                                    }
                                                                    key={i}
                                                                >
                                                                    <li className="modal-list">
                                                                        {
                                                                            region.city
                                                                        }
                                                                    </li>
                                                                </Link>
                                                            )
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <p>Carregando...</p>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mt-4 modal-bottom">
                                <Link
                                    to="/be-a-partner"
                                    className="modal-link2"
                                >
                                    <IntlMessages id="header.link.place.beboss" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        regions: state.restaurants.regions
    };
};

export default connect(mapStateToProps)(Meulocal);
