import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';

import './styles/bootstrap.css';
import './styles/index.scss';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {

    hydrate(
        <Provider store={store}>
            <App />
        </Provider >, rootElement
    );

} else {

    render(
        <Provider store={store}>
            <App />
        </Provider>, rootElement
    );

}

serviceWorker.unregister();
