import Model from '../../utils/Model';

class Profile extends Model {

	constructor(props) {
		super(props)

		this.initialize(props);

	}

	initialize(props) {
        super.initialize(props);
		
		this.first_name = props.first_name || '';
        this.last_name = props.last_name || '';
		this.born = props.born || '';
		this.gender = props.gender || '';
		this.phone = props.phone || '';
		this.avatar = props.avatar || '';
		this.intl = props.intl || '';

		this.name = props.name || '';
	}
}

export default Profile