import * as types from '../types';

// const initialState = {
// 	intl: false,
// 	locale: {
// 		languageId: 'english',
// 		locale: 'en',
// 		name: 'English',
// 		icon: 'us'
// 	}
// };

const initialState = {
	intl: false,
	locale: {
		"languageId": "portugal",
		"locale": "pt",
		"name": "Portugal",
		"icon": "pt"
	}
};

const reducer = (state = initialState, action) => {

	switch (action.type) {

		case types.GET_INTL:
			return getIntl(state);

		case types.SET_INTL:
			return setIntl(state, action.payload);

		case types.SET_REGION:
			return setRegion(state, action.payload);

		case types.SWITCH_LANGUAGE:
			return { ...state, locale: action.payload };

		default:
			return { ...state };
	}
}

const getIntl = (state) => {

	state = Object.assign({}, state, {
		intl: sessionStorage.getItem('intl') ? JSON.parse(sessionStorage.getItem('intl')) : {}
	});

	return state;
};

const setIntl = (state, payload) => {

	if (!payload) {

		payload = {
			'country_code': 'PT',
			'location': {
				'languages': [
					{
						'code': 'pt'
					}
				]
			}
		}
	}

	sessionStorage.setItem('intl', JSON.stringify(payload));

	return {
		...state,
		intl: payload,
	}
};

const setRegion = (state, payload) => {

	let intl = JSON.parse(sessionStorage.getItem('intl'));

	payload = { ...intl, region: payload };

	sessionStorage.setItem('intl', JSON.stringify(payload));

	return {
		...state,
		intl: payload,
	}
};

export default reducer;
