import * as types from '../types';

const initialState = {
    useTerms: {
        title: '',
        text: ''
    }
};

const reducer = (state = initialState, action) => {
    const { payload } = action;

    switch (action.type) {

        case types.GET_USE_TERMS:
            const useTerms = payload.docs.length ? payload.docs[0] : state.useTerms;
            return { ...state, useTerms }

        default:
            return { ...state };
    }
}


export default reducer;
