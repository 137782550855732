import Model from '../../utils/Model';

class Service extends Model {

	constructor(props) {
		super(props)

		this.initialize(props);

	}

	initialize(props) {
		super.initialize(props);
		
		this.name = props.name || '';
		this._id = props.service || props._id || '';

	}
}

export default Service;

