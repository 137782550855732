import appLocaleData from "react-intl/locale-data/en";
import enMessages from "../locales/en_US.json";

const enLang = {
    messages: {
        ...enMessages
    },
    locale: 'en',
    data: appLocaleData
};
export default enLang;
