import Model from "../../utils/Model";

class Address extends Model {
    constructor(props) {
        super(props);

        this.initialize(props);
    }

    initialize(props) {
        super.initialize(props);

        this.name = props.name || "";
        this.street = props.street || "";
        this.number = props.number || "";
        this.district = props.district || "";
        this.city = props.city || "";
        this.country = "Brasil";
        this.state = props.state || "";
        this.zip = props.zip || "";
        this.location = props.location || "";
    }
}

export default Address;
