import Http from "../../../utils/Http";
import * as actions from "../store/actions/actions-restaurant";
import * as actionsAuth from "../store/actions/actions-auth";

/**
 * get regions
 *
 * @returns {function(*)}
 */
export const getRegions = iso => {
    return dispatch =>
        new Promise((resolve, reject) => {
            return Http.get(`site/regions/${iso}`)
                .then(res => {
                    dispatch(actions.getRegions(res.data.data));

                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });
};

/**
 * get regions
 *
 * @returns {function(*)}
 */
export const getEvaluations = data => {
    return dispatch =>
        new Promise((resolve, reject) => {
            return Http.get(
                `/restaurant/reviews/${data.restaurant}/user/${data.order}`
            )
                .then(res => {
                    dispatch(actions.getEvaluations(res.data.data));
                    return resolve(res.data.data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
};

/**
 * get restaurants
 *
 * @returns {function(*)}
 */
export const getRestaurants = (iso, body) => {
    return dispatch =>
        new Promise((resolve, reject) => {
            return Http.post(`/site/restaurants/BR`, body)
                .then(res => {
                    dispatch(actions.getRestaurants(res.data.data));

                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });
};

/**
 * get restaurant by slug
 *
 * @returns {function(*)}
 */
export const getRestaurant = slug => {
    return dispatch =>
        new Promise((resolve, reject) => {
            return Http.get(`/site/restaurant/${slug}`)
                .then(res => {
                    dispatch(actions.getRestaurant(res.data.data));
                    // console.log(res.data.data);

                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });
};

/**
 * get services
 *
 * @returns {function(*)}
 */
export const getServices = () => {
    return dispatch =>
        new Promise((resolve, reject) => {
            return Http.get(`/site/services`)
                .then(res => {
                    dispatch(actions.getServices(res.data.data));

                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });
};

/**
 * get kitchens
 *
 * @returns {function(*)}
 */
export const getKitchens = () => {
    return dispatch =>
        new Promise((resolve, reject) => {
            return Http.get(`/site/kitchen`)
                .then(res => {
                    dispatch(actions.getKitchens(res.data.data));

                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });
};

/**
 * register user
 *
 * @returns {function(*)}
 */
export const registerUser = form => {
    console.log(form);
    return async dispatch => {
        const data = await new Promise((resolve, reject) => {
            return Http.post(`/site/restaurant/register`, {
                ...form,
                ticket_avg: null,
                region: { id: "650999123dcbc7cc947c4660" }
            })
                .then(res => {
                    dispatch(actionsAuth.authSigin(res.data.data.token));

                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });
    };
};

/**
 * get cep
 *
 * @returns {function(*)}
 */
export const getCEP = cep => {
    return () =>
        new Promise((resolve, reject) => {
            return Http.get(`/country/cep/${cep}`)
                .then(res => {
                    return resolve(res.data.data);
                })
                .catch(err => {
                    return reject(err);
                });
        });
};

/**
 * get countries
 *
 * @returns {function(*)}
 */
export const getCountries = () => {
    return dispatch =>
        new Promise((resolve, reject) => {
            return Http.get(`/country`)
                .then(res => {
                    dispatch(actions.getCountries(res.data.data));
                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });
};

/**
 * get cities
 *
 * @returns {function(*)}
 */
export const getCities = iso => dispatch =>
    new Promise((resolve, reject) => {
        if (!iso) {
            Http.get(`/country?type=cities`)
                .then(response => {
                    dispatch(actions.getCities(response.data.data));
                    return resolve(response);
                })
                .catch(err => reject(err.data));
        } else {
            Http.get(`/country?type=cities&state=${iso}`)
                .then(response => {
                    dispatch(actions.getCities(response.data.data));
                    return resolve(response);
                })
                .catch(err => reject(err.data));
        }
    });
/**
 * create restaurant
 *
 * @returns {function(*)}
 */
export const createRestaurant = restaurant => {
    return dispatch =>
        new Promise((resolve, reject) => {
            return Http.post(`/restaurant`, { ...restaurant, ticket_avg: null })
                .then(res => {
                    return resolve(res.data.data._id);
                })
                .catch(err => reject(err));
        });
};

/**
 * upload logo or cover
 *
 * @returns {function(*)}
 */
export const uploadLogoOrCover = (idRestaurant, image, type) => {
    return dispatch =>
        new Promise((resolve, reject) => {
            const data = new FormData();
            data.append("file", image, image.name);

            return Http.post(`/restaurant/upload/${idRestaurant}/${type}`, data)
                .then(res => {
                    return resolve();
                })
                .catch(err => reject(err));
        });
};

/**
 * upload photos
 *
 * @returns {function(*)}
 */
export const uploadPhotos = (idRestaurant, images) => {
    return dispatch =>
        new Promise((resolve, reject) => {
            if (images.length > 0) {
                const imgs = new FormData();

                images.forEach(file => {
                    imgs.append("file", file.value, file.value.name);
                });

                return Http.post(
                    `/restaurant/upload/${idRestaurant}/photos`,
                    imgs
                )
                    .then(res => {
                        return resolve();
                    })
                    .catch(err => reject(err));
            } else {
                return resolve();
            }
        });
};
