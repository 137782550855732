import React from "react";
import { Link } from "react-router-dom";

export function CookieBanner({ onAccept }) {
    return (
        <div
            style={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                backgroundColor: "rgba(51, 51, 51, 0.6)",
                color: "white",
                textAlign: "center",
                padding: "1em",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                overflow: "hidden",
                zIndex: 9999
            }}
        >
            <Link
                to="/terms"
                style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "items-center",
                    flexDirection: "column",
                    margin: 0,
                    color: "rgba(256, 256, 256, 0.9)",
                    fontSize: "0.9em",
                    textAlign: "start"
                }}
            >
                <p
                    style={{
                        margin: 0
                    }}
                >
                    Este site utiliza cookies para melhorar sua experiência.{" "}
                    <br />
                    Ao continuar navegando, você concorda com a nossa{" "}
                    <span
                        style={{
                            textDecoration: "underline"
                        }}
                    >
                        Política de Cookies.
                    </span>
                </p>
            </Link>

            <button
                onClick={onAccept}
                style={{
                    backgroundColor: "rgba(51, 51, 51, 0.3)",
                    border: "1px solid rgba(256, 256, 256, 0.9)",
                    textAlign: "center",
                    color: "rgba(256, 256, 256, 0.9)",
                    borderRadius: "5px",
                    padding: "0.25em 0.5em",
                    cursor: "pointer",
                    outline: "none",
                    opacity: 0.9
                }}
            >
                Aceitar
            </button>
        </div>
    );
}
