import appLocaleData from "react-intl/locale-data/pt";
import saMessages from "../locales/pt_PT.json";

const ptLang = {
    messages: {
        ...saMessages
    },
    locale: 'pt',
    data: appLocaleData
};
export default ptLang;
