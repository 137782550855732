import * as types from "../types";

const initialState = {
    restaurants: [],
    restaurant: {},
    regions: [],
    services: [],
    kitchens: [],
    countries: [],
    cities: [],
    evaluation: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_RESTAURANTS:
            return { ...state, restaurants: action.payload };

        case types.GET_RESTAURANT:
            return { ...state, restaurant: action.payload };

        case types.GET_REGIONS:
            return { ...state, regions: action.payload };

        case types.GET_SERVICES:
            return { ...state, services: action.payload };

        case types.GET_KITCHENS:
            return { ...state, kitchens: action.payload };

        case types.GET_COUNTRIES:
            return { ...state, countries: action.payload };

        case types.GET_CITIES:
            return { ...state, cities: action.payload };

        case types.GET_CEP:
            return { address: action.payload };

        case types.GET_EVALUATIONS:
            return { ...state, evaluation: action.payload };

        default:
            return { ...state };
    }
};

export default reducer;
