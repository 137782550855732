import * as types from "../types";

export const getRestaurants = payload => {
    return {
        type: types.GET_RESTAURANTS,
        payload
    };
};

export const getRestaurant = payload => {
    return {
        type: types.GET_RESTAURANT,
        payload
    };
};

export const getRegions = payload => {
    return {
        type: types.GET_REGIONS,
        payload
    };
};

export const getServices = payload => {
    return {
        type: types.GET_SERVICES,
        payload
    };
};

export const getKitchens = payload => {
    return {
        type: types.GET_KITCHENS,
        payload
    };
};

export const getCountries = payload => {
    return {
        type: types.GET_COUNTRIES,
        payload
    };
};

export const getCities = payload => {
    return {
        type: types.GET_CITIES,
        payload
    };
};

export const getCEP = payload => {
    return {
        type: types.GET_CEP,
        payload
    };
};

export const getEvaluations = payload => {
    return {
        type: types.GET_EVALUATIONS,
        payload
    };
};
