import Model from '../../utils/Model';

class Worksheet extends Model {

	constructor(props) {
		super(props)

		this.initialize(props);

	}

	initialize(props) {
		super.initialize(props);
		
		this.day = props.day || '';
		this.time_open = props.time_open || '';
		this.time_close = props.time_close || '';

	}
}

export default Worksheet;

