import Http from '../../../utils/Http';
import * as actions from '../store/actions/actions-auth';

/**
 * fetch the current logged in user
 *
 * @returns {function(*)}
 */
export const fetchUser = () => {

	return dispatch => (

		new Promise((resolve, reject) => {

			return Http.get('/identity')
				.then(res => {

					dispatch(actions.authUser(res.data.data));

					return resolve(res.data.data);

				})
				.catch(err => {
					return reject(err);
				})

		})
	);
};

/**
 * signIn
 *
 * @param credentials
 * @returns {function(*)}
 */
export const sigin = (credentials) => {

	return dispatch => (

		new Promise((resolve, reject) => {

			Http.post('/auth', credentials)
				.then(res => {

					if (res.data.success) {

						dispatch(actions.authSigin(res.data.data.token))

						return resolve();

					}

				})
				.catch(err => {

					let errorMessage = err.data.message;

					return reject(errorMessage);

				})
		})
	);
};

/**
 * signUp
 *
 * @param credentials
 * @returns {function(*)}
 */
export const signup = (form) => {

	return dispatch => (

		new Promise((resolve, reject) => {

			Http.post(`/site/user/register`, form)
				.then(res => {

					if (res.data.success) {

						dispatch(actions.authSigin(res.data.data.token))
					}

					return resolve(res.data);

				})
				.catch(err => {
					return reject(err);

				})
		})
	);
};

/**
 * forgotPassword
 *
 * @param credentials
 * @returns {function(*)}
 */
export const forgotPassword = (form) => {

	return dispatch => (

		new Promise((resolve, reject) => {

			Http.post(`/auth/forgot_password`, form)
				.then(res => {

					return resolve(res.data);

				})
				.catch(err => {

					let errorMessage = err.data.message;

					return reject(errorMessage);

				})
		})
	);
};

/**
 * changeForgotPassword
 *
 * @param credentials
 * @returns {function(*)}
 */
export const changeForgotPassword = (form) => {

	return dispatch => (

		new Promise((resolve, reject) => {

			Http.post(`/auth/reset_password`, form)
				.then(res => {

					return resolve(res.data);

				})
				.catch(err => {

					let errorMessage = err.data.message;

					return reject(errorMessage);

				})
		})
	);
};
