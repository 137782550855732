import Model from '../../utils/Model';

class Review extends Model {

	constructor(props) {
		super(props)

		this.initialize(props);

	}

	initialize(props) {
		super.initialize(props);
		
        this.user = props.user || '';
		this.rating = props.rating || [];
		this.average = props.average || '';
		this.comment = props.comment || '';
		this.reply = props.reply || '';
		this.attention = props.attention || '';
		this.private = props.private || false;
		this.moderate = props.moderate || false;
		this.active = props.active || false;

	}
}

export default Review;