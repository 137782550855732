import * as types from '../types';

const initialState = {
    daysToCancelOrder: 2
};

const reducer = (state = initialState, action) => {
    const { payload, type } = action;

    switch (type) {

        case types.GET_DAYS_TO_CANCEL_ORDER:
            return { ...state, daysToCancelOrder: payload.value }

        default:
            return { ...state };
    }
}


export default reducer;
