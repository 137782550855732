import React, { Component } from "react";
import { Link } from "react-router-dom";
import config from "~/config";

import Collapsible from "react-collapsible";

// intl
import IntlMessages from "~/utils/IntlMessages";

class FooterMobile extends Component {
    render() {
        return (
            <footer className="footer-mobile">
                <div className="copy-rights">
                    <img
                        src={`${config.app.CDN}/assets/images/logo-black.png`}
                        alt="Logo em preto F2E"
                    />
                    <span>
                        <IntlMessages id="footer.rights" />
                    </span>
                </div>

                <Collapsible
                    trigger={
                        <h4>
                            <IntlMessages id="footer.about" />
                        </h4>
                    }
                >
                    <ul>
                        <li>
                            <Link to="/howitworks">
                                <IntlMessages id="footer.howitworks" />
                            </Link>
                        </li>
                        <li>
                            <Link to="/terms">
                                <IntlMessages id="footer.terms" />
                            </Link>
                        </li>
                        <li>
                            <Link to="/privacy-policy">
                                <IntlMessages id="footer.privacy" />
                            </Link>
                        </li>
                    </ul>
                </Collapsible>

                <Collapsible trigger={<h4>Parceiros</h4>}>
                    <ul>
                        <li>
                            <Link to="/restaurant/pre-register">
                                Cadastre seu restaurante
                            </Link>
                        </li>
                        <li>
                            <Link to="/be-a-partner">Seja um parceiro</Link>
                        </li>
                    </ul>
                </Collapsible>

                <Collapsible trigger={<h4>Ajuda</h4>}>
                    <ul>
                        <li>
                            <Link to="/faq">Perguntas Frequentes</Link>
                        </li>

                        <li>
                            <Link to="/contact">Assessoria de imprensa</Link>
                        </li>

                        <li>
                            <Link to="/contact">Fale conosco</Link>
                        </li>
                    </ul>
                </Collapsible>
            </footer>
        );
    }
}

export default FooterMobile;
