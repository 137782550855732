import React, { Component } from "react";

// configs
// import config from "~/config";

// dependencies
import { connect } from "react-redux";

// json
// import languages from "../utils/languages.json";

// actions
// import Popover from "react-simple-popover";
import { switchLanguage } from "~/modules/web/store/actions/actions-geoip";

class ButtonLang extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    handleOpen = () => {
        const { open } = this.state;

        this.setState({ open: !open });
    };

    render() {
        // const { switchLanguage, locale } = this.props;
        // const { open } = this.state;

        return (
            <div className="header-collapsedesk">
                {/* <button
                    ref="button"
                    className="header-language"
                    onClick={this.handleOpen}>
                    <img className="header-btnlanguage" src={`${config.app.CDN}/assets/images/${locale.languageId}.png`} alt="Idioma" />
                </button>
                <Popover
                    placement='bottom'
                    container={this}
                    target={this.refs.button}
                    show={open}
                    onHide={this.handleOpen}
                >
                    <ul className="header-langitemcontainer">

                        {languages.map((item, i) => (

                            <li key={i} className="header-langitem">
                                <button
                                    className="header-langlink"
                                    onClick={() => { switchLanguage(item); this.handleOpen(); }}
                                >
                                    <img
                                        src={`${config.app.CDN}/assets/images/${item.languageId}.png`}
                                        className="header-langcountry"
                                        alt={item.name} />

                                    <span className="header-langtext">{item.name}</span>

                                </button>
                            </li>

                        ))}

                    </ul>
                </Popover> */}
                {/* <Collapsible
                    ref={el => this.refCollapsible = el}
                    trigger={
                        <button
                            className="header-language"
                            onClick={this.handleOpen}>
                            <img className="header-btnlanguage" src={`${config.app.CDN}/assets/images/${locale.languageId}.png`} alt="Idioma" />
                        </button>
                    }
                    open={open}
                    transitionTime={100}
                >


                </Collapsible> */}
            </div>
        );
    }
}
const mapStateToProps = state => ({
    locale: state.geoip.locale
});

export default connect(mapStateToProps, { switchLanguage })(ButtonLang);
